import { ProxyChannel } from 'vs/base/parts/ipc/common/ipc';
import { registerSingleton } from 'vs/platform/instantiation/common/extensions';
import { ILanguagePackService } from 'vs/platform/languagePacks/common/languagePacks';
import { IRemoteAgentService } from 'vs/workbench/services/remote/common/remoteAgentService';

// @ts-ignore: interface is implemented via proxy
export class LanguagePackService implements ILanguagePackService {

	declare readonly _serviceBrand: undefined;

	constructor(
		@IRemoteAgentService remoteAgentService: IRemoteAgentService,
	) {
		return ProxyChannel.toService<ILanguagePackService>(remoteAgentService.getConnection()!.getChannel('languagePacks'));
	}
}

registerSingleton(ILanguagePackService, LanguagePackService, true);
